
//Libs
import { useEffect, useState } from 'react';
import React from 'react';
import moment from 'moment';

//Utils
import Loader from '../../components/loader';
import Spinner from '../../components/spinner';
import Nav from '../components/Nav';
import Header from '../components/Header';
import { errorMessage } from '../../utils/utils';
import api from '../../utils/api';


import { TbTrashXFilled } from "react-icons/tb";



export default function () {

    const navName = 'sessions';
    const title = 'Session List';

    const [pageLoading, setPageLoading] = useState(true);
    const [nav, setNav] = useState(false);

    const [itemsError, setItemsError] = useState('');
    const [itemsLoading, setItemsLoading] = useState(true);
    const [items, setItems] = useState<any[]>([]);

    const [deleteId, setDeleteId] = useState('');

    useEffect(() => {
        if (!window.localStorage.token) {
            window.location.replace('/signin');
            return;
        }
        setPageLoading(false);
        getItems();

    }, []);


    async function getItems() {
        setItems([]);
        setItemsLoading(true);
        try {
            const res = await api.get(`/sessions`);
            const json = await res.json();
            if (res.ok) {
                setItems(json);
            } else {
                setItemsError(errorMessage(json));
            }

        } catch (error) {
            setItemsError(errorMessage(error));
        }

        setItemsLoading(false);
    }

    async function deleteItem(id: string) {
        setDeleteId(id);
        try {
            const res = await api.delete(`/sessions/${id}`);
            if (res.ok) {

                // getItems();

                setItems([...items.filter((item) => item._id.toString() !== id)]);

            } else {

            }

        } catch (error) {

        }

        setDeleteId('');
    }


    if (pageLoading) {
        return (<div className='w-screen h-screen text-center flex flex-col bg-white justify-center'><Loader /></div>)
    }

    return (
        <>
            <div className='flex flex-row w-full'>

                <div className="hidden xl:block"><Nav nav={navName} className='fixed block left-[0px]' /></div>

                <main className='xl:ml-[250px] grow w-screen flex flex-col'>
                    <Header title={`${title} (${items.length})`} setNav={() => { setNav(!nav) }} />
                    <section>
                        <Large className='hidden lg:block' items={items} loading={itemsLoading} error={itemsError} deleteId={deleteId} setItems={setItems} onDelete={deleteItem} />
                    </section>
                </main>
            </div>

            <div className='xl:hidden'>
                {nav && <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0" onClick={() => setNav(false)}></div>}
                <div className={nav ? "fixed top-0 left-0 w-[250px] h-screen bg-white z-10 duration-300" : "fixed top-0 left-[-100%] w-[250px] h-screen bg-white z-10 duration-300"}>
                    <Nav nav={navName} />
                </div>
            </div>
        </>
    )

}


type ItemProp = {
    items: any[]
    loading: boolean
    error: string | TrustedHTML
    className?: string
    deleteId?: string
    onDelete?: (id: string) => void,
    setItems?: (items: any[]) => void,
}

function Large({ items, loading, error, className, deleteId, onDelete, setItems }: ItemProp) {

    const colSpan = 6;
    const [activeId, setActiveId] = useState('');
    const [active, setActive] = useState<any>();

    async function getToken(id: string) {

        try {

            if (typeof setItems === 'function') {

                const res = await api.get(`/sessions/${id}`);

                if (res.ok) {
                    const index = items.findIndex((item) => item._id.toString() === id);

                    if (index > -1) {
                        const json = await res.json();
                        items[index].token = json.token;
                        items[index].refresh = json.refresh;
                        setItems(items);
                        setActive(items[index]);
                    }
                }
            }

        } catch (error) {

        }
    }


    return <div className={`w-full my-5 px-5 ${className && className}`}>
        <table className="table-fixed w-full divide-y divide-gray-300 bg-white shadow-md rounded-lg ring-1 ring-gray-900/5">
            <thead className='h-[40px] text-white text-left bg-green-500'>
                <tr>
                    <th className='text-xs font-medium px-3 rounded-tl-md w-[190px]'>Name</th>
                    <th className='text-xs font-medium px-3'>Email</th>
                    <th className='text-xs font-medium px-3'>IP</th>
                    <th className='text-xs font-medium px-3 w-[150px]'>Revoked</th>
                    <th className='text-xs font-medium px-3 w-[120px]'>Created</th>
                    <th className='text-xs font-medium px-3 rounded-tr-md w-[30px]'></th>
                </tr>
            </thead>

            <tbody className={`divide-y divide-gray-200 ${!loading && 'hidden'}`}>
                <tr className='h-[40px]'>
                    <td colSpan={colSpan} className='text-center'>
                        <div className='w-full'><Spinner className='w-8 h-8 inline text-blue-500 fill-gray-400' /></div>
                    </td>
                </tr>
            </tbody>

            <tbody className={`divide-y divide-gray-200 ${(loading || !error) && 'hidden'}`}>
                <tr className='h-[40px]'>
                    <td colSpan={colSpan} className='text-center'>
                        <div className='text-red-500 italic text-sm'><div dangerouslySetInnerHTML={{ __html: error }} /></div>
                    </td>
                </tr>
            </tbody>

            <tbody className={`divide-y divide-gray-200 ${(loading || items?.length > 0 || error) && 'hidden'}`}>
                <tr className='h-[40px]'>
                    <td colSpan={colSpan} className='text-center'>
                        <div className='italic text-sm'>No data</div>
                    </td>
                </tr>
            </tbody>

            <tbody className={`divide-y divide-gray-200 ${(items?.length === 0 || loading || error) && 'hidden'}`}>

                {items.map((item: any, index: number) => {

                    const id = item._id.toString();

                    const revokedDate = item.revoked_date ? moment(item.revoked_date).format('MMM DD, YYYY') : undefined;

                    var age = item.date_created ? moment(item.date_created).startOf('hour').fromNow() : undefined;

                    // try {
                    //     const created = Date.parse(item.date_created?.toString());

                    //     if (created) {
                    //         const now = new Date();
                    //         const diff = (now.getTime() - created) / (1000 * 60 * 60 * 24);
                    //         age = diff.toFixed(0);
                    //     }
                    // } catch (error) { }

                    return (<React.Fragment key={`${index}-item`}>
                        <tr key={`${index}-main`} onClick={() => {

                            const newId = id === activeId ? '' : id;

                            if (newId) {
                                if (!item.token || !item.refresh) {
                                    setActive(undefined);
                                    getToken(newId);
                                } else {
                                    setActive(item);
                                }
                            } else {
                                setActive(undefined);
                            }

                            setActiveId(newId);

                        }} className={`cursor-pointer text-xs h-[40px] ${id === activeId ? 'bg-blue-100' : 'hover:bg-green-50'} ${item.revoked_reason && 'bg-gray-100'}`}>

                            <td className="whitespace-nowrap px-3">{item.user?.name}</td>
                            <td className="whitespace-nowrap px-3 flex flex-row gap-x-2 h-[40px] items-center">
                                <div>{item.user?.email}</div>
                                {item.active && <div className='bg-green-500 rounded-md px-1 text-[8px] text-white'>active</div>}
                                {item.type === 'admin' && <div className='bg-blue-500 rounded-md px-1 text-[8px] text-white'>admin</div>}
                            </td>

                            <td className="whitespace-nowrap px-3">{item.ip}</td>
                            <td className="whitespace-nowrap px-3">
                                <div>{item.revoked_reason}</div>
                                <div>{revokedDate}</div>
                            </td>

                            <td className="whitespace-nowrap px-3 text-xs">{age}</td>
                            <td className="whitespace-nowrap text-center">{deleteId === id ? <Spinner /> : <TbTrashXFilled className='inline text-red-500 text-lg cursor-pointer' onClick={() => {
                                if (typeof onDelete === 'function') {
                                    onDelete(id);
                                }
                            }} />}</td>

                        </tr>

                        <tr key={`${index}-sub`} className={`text-xs shadow-md ${!(id === activeId) && 'hidden'}`}>
                            <td colSpan={colSpan} className='p-2'>

                                {!active?.token || !active?.refresh ?
                                    <div className='flex flex-row gap-x-2 items-center justify-center'><Spinner className='w-3 h-3 inline text-blue-500 fill-gray-400' /><span className='text-gray-600 italic text-[10px]'>Getting token... Please Wait!</span></div> :
                                    <div className='flex flex-col gap-y-2'>

                                        <div className='flex flex-row items-center'>
                                            <div className='w-[60px]'>Token:</div>
                                            <textarea className='grow p-1 border w-full' value={item.token} />
                                        </div>

                                        <div className='flex flex-row items-center'>
                                            <div className='w-[60px]'>Refresh:</div>
                                            <textarea className='grow p-1 border w-full' value={item.refresh} />
                                        </div>
                                    </div>
                                }

                            </td>
                        </tr>
                    </React.Fragment>)
                })}

            </tbody>

        </table>
    </div>

}