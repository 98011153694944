import { useEffect, useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { errorMessage } from "../../utils/utils";
import Spinner from "../../components/spinner";
import api from "../../utils/api";

export default function SignIn() {

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState('');
  const [remember, setRemember] = useState(false);

  useEffect(() => {
    const _temp = window.localStorage.temp;
    if (_temp) {
      setEmail(_temp);
      setRemember(true);
    }

    if (window.localStorage.token) {
      window.location.replace('/dashboard');
    }

  }, []);

  async function signIn(e: any) {
    e.preventDefault();

    window.localStorage.removeItem('refresh');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('id');
    window.localStorage.removeItem('email');
    window.localStorage.removeItem('name');
    setShowPass(false);
    setError('');
    setLoading(true);
    
    try {
      const res = await api.post(`/auth/signin`, { email, password });
      const json = await res.json();
      if (res.ok) {
        if (remember) {
          window.localStorage.temp = email;
        }
        window.localStorage.token = json.token;
        window.localStorage.refresh = json.refresh;
        window.localStorage.id = json.id;
        window.localStorage.email = json.email;
        window.localStorage.name = json.name;
        window.location.replace('/dashboard');
        return;

      } else {
        setError(errorMessage(json));
      }
    } catch (err) {
      setError(errorMessage(err));
    }
    setLoading(false);
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* <img
              alt="Your Company"
              src="https://tailwindui.com/img/logos/mark.svg?color=blue&shade=600"
              className="mx-auto h-10 w-auto"
            /> */}
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form onSubmit={signIn}>
              <div className="mb-5">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    value={email}
                    disabled={loading}
                    autoComplete="email"
                    placeholder="name@domain.com"
                    className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-sm ring-gray-300 placeholder:text-gray-400"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-5">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    id="password"
                    name="password"
                    type={showPass ? 'test' : "password"}
                    required
                    value={password}
                    disabled={loading}
                    placeholder="Enter password"
                    autoComplete="current-password"
                    className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-sm placeholder:text-gray-400"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span className="absolute inset-y-0 end-0 flex items-center px-3 cursor-pointer text-gray-500" onClick={ loading ? undefined : () => setShowPass(!showPass)}>{showPass ? <FaRegEye className="text-blue-500" /> : <FaRegEyeSlash />}</span>
                </div>
              </div>

              <div className="flex items-center justify-between mb-5">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    checked={remember}
                    disabled={loading}
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                    onChange={(e)=> setRemember(e.target.checked)}
                  />
                  <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                    Remember me
                  </label>
                </div>

                <div className="text-sm leading-6">
                  <a href="/password-reset" className="font-semibold text-black hover:text-blue-600">
                    Forgot password?
                  </a>
                </div>
              </div>

              {error && <div className="text-center text-red-500 italic text-sm mb-5"><div dangerouslySetInnerHTML={{ __html: error }} /></div>}

              <div className="mt-10">
                <button
                  type="submit"
                  disabled={loading}
                  className="disabled:bg-gray-500 flex flex-row items-center w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black">
                  {loading && <Spinner className="mr-2"/>} Sign in
                </button>
              </div>

            </form>

          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            <a href="/" className="font-semibold leading-6 text-black hover:text-blue-600">
              Go Back To Home
            </a>
          </p>

        </div>
      </div>
    </>
  )
}
