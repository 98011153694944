// import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './index.css';

import Main from './main/main';

import SignIn from './pages/signin/signin';
import Dashboard from './pages/dashboard/dashboard';
import Users from './pages/users/users';
import Sessions from './pages/sessions/sessions';
import Secrets from './pages/secrets/secrets';
import Servers from './pages/servers/servers';

// import PlainNotFound from './pages/404/404_plain';

import NotFound from './pages/404/404';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  // </React.StrictMode>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Main />} />
      
      <Route path="signin" element={<SignIn />} />

      <Route path="dashboard" element={<Dashboard />} />
      
      <Route path="users" element={<Users />} />
      <Route path="sessions" element={<Sessions />} />
      <Route path="secrets" element={<Secrets />} />
      <Route path="servers" element={<Servers />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);