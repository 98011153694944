
//Libs
import { useEffect, useState } from 'react';
import React from 'react';
import moment from 'moment';

//Utils
import Loader from '../../components/loader';
import Spinner from '../../components/spinner';
import Nav from '../components/Nav';
import Header from '../components/Header';
import { errorMessage } from '../../utils/utils';
import api from '../../utils/api';
import { TbTrashXFilled } from 'react-icons/tb';


export default function () {

    const navName = 'secrets';
    const title = 'Secret List';

    const [pageLoading, setPageLoading] = useState(true);

    const [nav, setNav] = useState(false);


    const [itemsError, setItemsError] = useState('');
    const [itemsLoading, setItemsLoading] = useState(true);
    const [items, setItems] = useState<any[]>([]);

    const [deleteId, setDeleteId] = useState<any>('');
    const [deleteError, setDeleteError] = useState('');


    useEffect(() => {
        if (!window.localStorage.token) {
            window.location.replace('/signin');
            return;
        }
        setPageLoading(false);
        getItems();

    }, []);


    async function getItems() {
        setItems([]);
        setItemsLoading(true);
        try {
            const res = await api.get(`/secrets`);
            const json = await res.json();
            if (res.ok) {
                setItems(json);
            } else {
                setItemsError(errorMessage(json));
            }

        } catch (error) {
            setItemsError(errorMessage(error));
        }

        setItemsLoading(false);
    }

    async function deleteItem(name?: string, ip?: string) {

        setDeleteId(name);

        try {

            const res = await api.delete('/secrets', { name, ip });
            
            if (res.ok) {
                setItems([...items.filter((item) => item.name !== name)]);
            } else {
                const json = await res.json();
                setDeleteError(errorMessage(json));
            }

        } catch (error) {
            setDeleteError(errorMessage(error));
        }

        setDeleteId('');

    }


    if (pageLoading) {
        return (<div className='w-screen h-screen text-center flex flex-col bg-white justify-center'><Loader /></div>)
    }

    return (
        <>
            <div className='flex flex-row w-full'>

                <div className="hidden xl:block"><Nav nav={navName} className='fixed block left-[0px]' /></div>

                <main className='xl:ml-[250px] grow w-screen flex flex-col'>
                    <Header title={`${title} (${items.length})`} setNav={() => { setNav(!nav) }} />
                    <section>
                        <Large className='hidden lg:block' items={items} loading={itemsLoading} deleteId={deleteId} error={itemsError} onDelete={(name, ip) => {
                            deleteItem(name, ip);
                        }} />
                    </section>
                </main>
            </div>

            <div className='xl:hidden'>
                {nav && <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0" onClick={() => setNav(false)}></div>}
                <div className={nav ? "fixed top-0 left-0 w-[250px] h-screen bg-white z-10 duration-300" : "fixed top-0 left-[-100%] w-[250px] h-screen bg-white z-10 duration-300"}>
                    <Nav nav={navName} />
                </div>
            </div>
        </>
    )

}


type ItemProp = {
    items: any[],
    loading: boolean,
    error: string | TrustedHTML,
    className?: string,
    deleteId?: string,
    onDelete?: (name?: string, ip?: string) => void,
}

function Large({ items, loading, error, deleteId, className, onDelete }: ItemProp) {

    const colSpan = 9;

    return <div className={`w-full my-5 px-5 ${className && className}`}>
        <table className="table-fixed w-full divide-y divide-gray-300 bg-white shadow-md rounded-lg ring-1 ring-gray-900/5">
            <thead className='h-[40px] text-white text-left bg-green-500'>
                <tr>
                    <th className='text-xs font-medium px-3 rounded-tl-md'>User</th>
                    <th className='text-xs font-medium px-3 w-[120px]'>Name</th>
                    <th className='text-xs font-medium px-3 w-[80px]'>Service</th>
                    <th className='text-xs font-medium px-3 w-[80px]'>Port</th>
                    <th className='text-xs font-medium px-3 w-[80px]'>Forward</th>
                    <th className='text-xs font-medium px-3 w-[80px]'>Subs</th>
                    <th className='text-xs font-medium text-center px-3 w-[80px]'>Days Left</th>
                    <th className='text-xs font-medium px-3 w-[180px]'>Created</th>
                    <th className='text-sm font-medium px-3 rounded-tr-md w-[30px]'></th>
                </tr>
            </thead>

            <tbody className={`divide-y divide-gray-200 ${!loading && 'hidden'}`}>
                <tr className='h-[40px]'>
                    <td colSpan={colSpan} className='text-center'>
                        <div className='w-full'><Spinner className='w-8 h-8 inline text-blue-500 fill-gray-400' /></div>
                    </td>
                </tr>
            </tbody>

            <tbody className={`divide-y divide-gray-200 ${(loading || !error) && 'hidden'}`}>
                <tr className='h-[40px]'>
                    <td colSpan={colSpan} className='text-center'>
                        <div className='text-red-500 italic text-sm'><div dangerouslySetInnerHTML={{ __html: error }} /></div>
                    </td>
                </tr>
            </tbody>

            <tbody className={`divide-y divide-gray-200 ${(loading || items?.length > 0 || error) && 'hidden'}`}>
                <tr className='h-[40px]'>
                    <td colSpan={colSpan} className='text-center'>
                        <div className='italic text-sm'>No data</div>
                    </td>
                </tr>
            </tbody>


            <tbody className={`divide-y divide-gray-200 ${(items?.length === 0 || loading || error) && 'hidden'}`}>

                {items.map((item: any, index: number) => {

                    const created = item.date_created ? moment(item.date_created).format('MMM DD, YYYY | hh:mm:ss a') : undefined;
                    // const expiration = item.date_expiration ? moment(item.date_expiration).format('MMM DD, YYYY | hh:mm:ss a') : undefined;

                    var daysLeft;

                    try {
                        const expDate = Date.parse(item.date_expiration?.toString());

                        if (expDate) {
                            const now = new Date();
                            const diff = (expDate - now.getTime()) / (1000 * 60 * 60 * 24);
                            daysLeft = diff.toFixed(0);
                        }
                    } catch (error) { }

                    return (<React.Fragment key={`${index}-item`}>
                        <tr key={`${index}-main`} className={`text-xs h-[50px] hover:bg-green-50`}>

                            <td className="h-[50px] px-3 flex flex-row items-center gap-x-2">

                                <div className={`h-[12px] w-[12px] rounded-full ${item.active ? 'bg-green-500' : 'bg-gray-500'}`} />
                                {item.email ?? <div>
                                    <div className='font-semibold'>{item.user?.name}</div>
                                    <div>{item.user?.email}</div>

                                </div>}
                                {!item.secret && <div className='text-[10px] text-white bg-orange-400 rounded-md px-1'>missing</div>}
                                {item.secret?.disabled === 'true' && <div className='text-[10px] text-white bg-gray-400 rounded-md px-1'>disabled</div>}
                            </td>

                            <td className="whitespace-nowrap px-3 font-semibold text-blue-500">{item.name}</td>

                            <td className="whitespace-nowrap px-3">{item.service}</td>

                            <td className="whitespace-nowrap px-3">{item.app_port === 'custom' ? `${item.protocol}:${item.custom}` : item.app_port}</td>
                            <td className="whitespace-nowrap px-3">{item.port}</td>
                            <td className="whitespace-nowrap px-3">{item.subscription}</td>
                            <td className="whitespace-nowrap px-3 text-center">{daysLeft}</td>
                            <td className="whitespace-nowrap px-3">{created}</td>
                            <td className="whitespace-nowrap text-center">{deleteId === item.name ? <Spinner /> : <TbTrashXFilled className='inline text-red-500 text-lg cursor-pointer' onClick={() => {
                                if (typeof onDelete === 'function') {
                                    onDelete(item.name, item.server_ip);
                                }
                            }} />}</td>

                        </tr>

                        <tr key={`${index}-sub`} className={`shadow-md rounded-lg hidden`}>

                        </tr>
                    </React.Fragment>)
                })}

            </tbody>
        </table>
    </div>

}