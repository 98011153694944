
import { useEffect, useState } from 'react'
import Loader from '../../components/loader';
import UserNav from '../components/Nav';
import UserHeader from '../components/Header';


export default function UserDashboard() {

  const [pageLoading, setPageLoading] = useState(true);
  const [name, setName] = useState('');

  useEffect(() => {
    const token = window.localStorage.token;

    if (!token) {
      window.location.replace('/signin');
      return;
    }

    setName(window.localStorage.name ?? window.localStorage.email ?? 'user');

    setPageLoading(false);

  }, []);

  const [nav, setNav] = useState(false);


  if (pageLoading) {
    return (<div className='w-screen h-screen text-center flex flex-col bg-white justify-center'><Loader /></div>)
  }

  return (
    <>
      <div className='flex flex-row'>

        <div className='hidden xl:block'><UserNav nav='dashboard' className='fixed block left-[0px]' /></div>

        <main className='xl:ml-[250px] grow w-screen flex flex-col'>
          <UserHeader title='Dashboard' setNav={() => { setNav(!nav) }} />

          <section className='grow'>

            <div className='text-lg m-5'>Hello, Admin <span className='font-medium'>{name}</span>. Welcome to your dashboard!</div>

          </section>

        </main>
      </div>

      <div className='xl:hidden'>
        {nav && <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0" onClick={() => setNav(false)}></div>}
        <div className={nav ? "fixed top-0 left-0 w-[250px] h-screen bg-white z-10 duration-300" : "fixed top-0 left-[-100%] w-[250px] h-screen bg-white z-10 duration-300"}>
          <UserNav nav='dashboard' />
        </div>
      </div>

    </>
  )

}