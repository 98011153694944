
import Header from '../components/header';
import Footer from '../components/footer';

export default function Main() {
  return (
    <div className='flex flex-col h-screen'>
      <Header className='flex' />

      <section className='flex-1 w-full py-[60px] md:py-[200px]'>

        {/* <div className='mx-auto w-full md:w-[1024px] md:px-8 text-center'>

          <div className='text-[50px] md:text-[60px] font-medium leading-tight'>
            Server remote <span className='text-blue-600'>made simple</span><br />for small businesses.
          </div>

          <div className='px-5 md:text-xl md:w-[700px] mx-auto mt-5'>
            Manage and access your server from anywhere. It’s designed for ease of use, offering secure and reliable remote control to keep your systems running smoothly.
          </div>

          <div className='mt-[50px] mx-auto md:w-[700px] text-center flex flex-col md:flex-row items-center justify-center gap-5'>
            <a href='/demo' className='bg-white border-[1px] border-gray-400 rounded-full px-5 py-3 hover:bg-blue-600 hover:text-white'>Get A Free Demo</a>

            <a href='/signin' className='bg-black text-white rounded-full px-5 py-3 hover:bg-blue-600'>Buy Subscription</a>
          </div>

        </div> */}
      </section>


      <Footer />
    </div>
  )
}