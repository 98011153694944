
//Libs
import { useEffect, useState } from 'react';
import React from 'react';
import moment from 'moment';

//Utils
import Loader from '../../components/loader';
import Spinner from '../../components/spinner';
import Nav from '../components/Nav';
import Header from '../components/Header';
import { errorMessage } from '../../utils/utils';
import api from '../../utils/api';




export default function () {

    const navName = 'users';
    const title = 'User List';

    const [pageLoading, setPageLoading] = useState(true);
    const [nav, setNav] = useState(false);

    const [itemsError, setItemsError] = useState('');
    const [itemsLoading, setItemsLoading] = useState(true);
    const [items, setItems] = useState<any[]>([]);

    useEffect(() => {
        if (!window.localStorage.token) {
            window.location.replace('/signin');
            return;
        }
        setPageLoading(false);
        getItems();

    }, []);


    async function getItems() {
        setItems([]);
        setItemsLoading(true);
        try {
            const res = await api.get(`/users`);
            const json = await res.json();
            if (res.ok) {
                setItems(json);
            } else {
                setItemsError(errorMessage(json));
            }

        } catch (error) {
            setItemsError(errorMessage(error));
        }

        setItemsLoading(false);
    }


    if (pageLoading) {
        return (<div className='w-screen h-screen text-center flex flex-col bg-white justify-center'><Loader /></div>)
    }

    return (
        <>
            <div className='flex flex-row w-full'>

                <div className="hidden xl:block"><Nav nav={navName} className='fixed block left-[0px]' /></div>

                <main className='xl:ml-[250px] grow w-screen flex flex-col'>
                    <Header title={`${title} (${items.length})`} setNav={() => { setNav(!nav) }} />
                    <section>
                        <Large className='hidden lg:block' items={items} loading={itemsLoading} error={itemsError} />
                    </section>
                </main>
            </div>

            <div className='xl:hidden'>
                {nav && <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0" onClick={() => setNav(false)}></div>}
                <div className={nav ? "fixed top-0 left-0 w-[250px] h-screen bg-white z-10 duration-300" : "fixed top-0 left-[-100%] w-[250px] h-screen bg-white z-10 duration-300"}>
                    <Nav nav={navName} />
                </div>
            </div>
        </>
    )

}


type ItemProp = {
    items: any[],
    loading: boolean,
    error: string | TrustedHTML,
    className?: string,
}

function Large({ items, loading, error, className }: ItemProp) {

    return <div className={`w-full my-5 px-5 ${className && className}`}>
        <table className="table-fixed w-full divide-y divide-gray-300 bg-white shadow-md rounded-lg ring-1 ring-gray-900/5">
            <thead className='h-[40px] text-white text-left bg-green-500'>
                <tr>
                    <th className='text-xs font-medium px-3 rounded-tl-md'>ID</th>
                    <th className='text-xs font-medium px-3'>Name</th>
                    <th className='text-xs font-medium px-3'>Email</th>
                    <th className='text-xs font-medium px-3 rounded-tr-md'>Created</th>
                </tr>
            </thead>

            <tbody className={`divide-y divide-gray-200 ${!loading && 'hidden'}`}>
                <tr className='h-[40px]'>
                    <td colSpan={4} className='text-center'>
                        <div className='w-full'><Spinner className='w-8 h-8 inline text-blue-500 fill-gray-400' /></div>
                    </td>
                </tr>
            </tbody>

            <tbody className={`divide-y divide-gray-200 ${(loading || !error) && 'hidden'}`}>
                <tr className='h-[40px]'>
                    <td colSpan={4} className='text-center'>
                        <div className='text-red-500 italic text-sm'><div dangerouslySetInnerHTML={{ __html: error }} /></div>
                    </td>
                </tr>
            </tbody>

            <tbody className={`divide-y divide-gray-200 ${(loading || items?.length > 0 || error) && 'hidden'}`}>
                <tr className='h-[40px]'>
                    <td colSpan={4} className='text-center'>
                        <div className='italic text-sm'>No data</div>
                    </td>
                </tr>
            </tbody>


            <tbody className={`divide-y divide-gray-200 ${(items?.length === 0 || loading || error) && 'hidden'}`}>

                {items.map((item: any, index: number) => {


                    return (<React.Fragment key={`${index}-item`}>
                        <tr key={`${index}-main`} className={`text-xs h-[40px] hover:bg-green-50`}>

                            <td className="whitespace-nowrap px-3">{item._id}</td>

                            <td className="whitespace-nowrap px-3">{item.name}</td>

                            <td className="whitespace-nowrap px-3">{item.email}</td>

                            <td className="whitespace-nowrap px-3">{moment(item.date_created).format('MMM DD, YYYY | hh:mm:ss a')}</td>

                        </tr>

                        <tr key={`${index}-sub`} className={`shadow-md rounded-lg hidden`}>

                        </tr>
                    </React.Fragment>)
                })}

            </tbody>
        </table>
    </div>

}