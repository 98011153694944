import { BiSolidDashboard } from "react-icons/bi";
import { PiLinkFill } from "react-icons/pi";
// import logo from "../../../logo.png";
import { IoIosListBox } from "react-icons/io";
import { HiUsers } from "react-icons/hi2";

export default function ({ nav, className }: any) {
  return (
    <nav className={`flex w-[250px] bg-green-500 shadow-md h-screen flex flex-col ${className && className}`}>

      <div className='h-[45px] w-full'>

        <div className="flex flex-row items-center justify-center pt-2 gap-2">
          {/* <a href="/"><img className="w-[40px] h-[40px] rounded-full" src={''} alt="" /></a> */}
          <a href="/"><span className="text-white font-bold text-xl tracking-widest">Admin</span></a>
        </div>
        
      </div>
      <hr />

      <a href='/dashboard' className={`mb-[1px] text-white h-[50px] items-center flex px-3 font-medium hover:bg-green-800 ${nav === 'dashboard' && 'bg-green-600'}`}>
        <BiSolidDashboard className="mr-2" /> Dashboard
      </a>

      <a href='/users' className={`mb-[1px] text-white h-[50px] items-center flex px-3 font-medium hover:bg-green-800 ${nav === 'users' && 'bg-green-600'}`}>
        <HiUsers className="mr-2" /> Users
      </a>

      <a href='/sessions' className={`mb-[1px] text-white h-[50px] items-center flex px-3 font-medium hover:bg-green-800 ${nav === 'sessions' && 'bg-green-600'}`}>
        <HiUsers className="mr-2" /> Sessions
      </a>

      <a href='/secrets' className={`mb-[1px] text-white h-[50px] items-center flex px-3 font-medium hover:bg-green-800 ${nav === 'secrets' && 'bg-green-600'}`}>
        <IoIosListBox className="mr-2" /> Secrets
      </a>

      <a href='/servers' className={`mb-[1px] text-white h-[50px] items-center flex px-3 font-medium hover:bg-green-800 ${nav === 'servers' && 'bg-green-600'}`}>
        <IoIosListBox className="mr-2" /> Servers
      </a>

    </nav>
  )
}
